import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import ReCAPTCHA from "react-google-recaptcha"
import Frame from "../components/Frame"
import SEO from "../components/seo"
import ThermalBackground from "../components/ThermalBackground"
import Overlap from "../components/Overlap"
import Button from "../components/ui/Button"
import Cushion from "../components/ui/Cushion"
import Input from "../components/ui/Input"
import MaxWidth from "../components/ui/MaxWidth"
import Rectangle from "../components/ui/Rectangle"
import Shadow from "../components/ui/Shadow"
import TextArea from "../components/ui/TextArea"
import Typography from "../components/ui/Typography"
import ConcaveBottom from "../components/ConcaveBottom"

function validate(values) {
  const errors = []

  if (!values.name) {
    errors.push("Please provide your name.")
  }

  if (!values.email) {
    errors.push("Please provide your email address.")
  }

  if (!values.telephone) {
    errors.push("Please provide your telephone number.")
  }

  if (!values.recaptcha) {
    errors.push("Please verify you are not a robot.")
  }

  return errors
}

const Contact = ({ data, location }) => {
  const { contactDetailsJson } = data
  const { state: { message: predefinedMessage = null } = {} } = location
  const [form, setForm] = useState({ values: { message: predefinedMessage } })
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const formRef = useRef()
  const errors = validate(form.values)

  const onChange = e => {
    setForm({
      ...form,
      values: { ...form.values, [e.target.name]: e.target.value },
    })
  }
  return (
    <Frame
      currentPathname={location.pathname}
      contactDetails={contactDetailsJson}
    >
      <SEO title="Contact Us" />{" "}
      <ThermalBackground component="div">
        <ConcaveBottom fill="vLightGrey">
          <Cushion
            component="div"
            horizontal="small"
            top="xxxxxlarge"
            bottom={300}
            responsive={[
              { minWidth: "palm", props: { horizontal: "medium" } },
              { minWidth: "tab", props: { horizontal: "xlarge" } },
            ]}
          >
            <Typography component="div" align="center">
              <Typography component="div" preset="hero" color="white">
                Ask us a question
              </Typography>
            </Typography>
          </Cushion>
        </ConcaveBottom>
      </ThermalBackground>
      <MaxWidth maxWidth={800} center>
        <Cushion
          component="div"
          horizontal="small"
          top="medium"
          bottom="xxlarge"
          responsive={[{ minWidth: "palm", props: { horizontal: "medium" } }]}
        >
          <Overlap amount={300}>
            <Shadow>
              <Rectangle fill="white" radius="rounded">
                <Cushion
                  horizontal="small"
                  vertical="medium"
                  responsive={[
                    { minWidth: "palm", props: { horizontal: "medium" } },
                  ]}
                >
                  <form
                    name="contactForm"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    data-netlify-recaptcha="true"
                    ref={formRef}
                  >
                    <Input hidden name="bot-field" />
                    <Input hidden name="form-name" value="contactForm" />
                    <Cushion component="div" bottom="medium">
                      <Cushion bottom="xsmall">
                        <Typography
                          component="label"
                          preset="label"
                          color="medGrey"
                          htmlFor="name"
                          style={{ display: "inline-block" }}
                        >
                          Name
                        </Typography>
                      </Cushion>
                      <Input
                        name="name"
                        id="name"
                        onChange={onChange}
                        required
                        value={form.values.name}
                        placeholder="Joe Bloggs"
                      />
                    </Cushion>
                    <Cushion component="div" bottom="medium">
                      <Cushion bottom="xsmall">
                        <Typography
                          component="label"
                          preset="label"
                          color="medGrey"
                          htmlFor="email"
                          style={{ display: "inline-block" }}
                        >
                          Email
                        </Typography>
                      </Cushion>
                      <Input
                        name="email"
                        id="email"
                        type="email"
                        onChange={onChange}
                        required
                        value={form.values.email}
                        placeholder="joe@blogs.com"
                      />
                    </Cushion>
                    <Cushion component="div" bottom="medium">
                      <Cushion bottom="xsmall">
                        <Typography
                          component="label"
                          preset="label"
                          color="medGrey"
                          htmlFor="telephone"
                          style={{ display: "inline-block" }}
                        >
                          Telephone
                        </Typography>
                      </Cushion>
                      <Input
                        name="telephone"
                        id="telephone"
                        type="tel"
                        onChange={onChange}
                        required
                        value={form.values.telephone}
                        placeholder="07123456789"
                      />
                    </Cushion>
                    <Cushion component="div" bottom="medium">
                      <Cushion bottom="xxsmall">
                        <Typography
                          component="label"
                          preset="label"
                          color="medGrey"
                          htmlFor="message"
                          style={{ display: "inline-block" }}
                        >
                          Message
                        </Typography>
                      </Cushion>
                      <Cushion component="div" bottom="medium">
                        <TextArea
                          name="message"
                          id="message"
                          required
                          onChange={onChange}
                          value={form.values.message}
                          placeholder="Hi A-Rated PowerFlush, can you help me with something?"
                        />
                      </Cushion>
                      <Cushion component="div">
                        <ReCAPTCHA
                          sitekey="6Lf5ROAZAAAAAJJbcOJxSWGN_KnKVTRXJMIwhUga"
                          onChange={value => {
                            setForm({
                              ...form,
                              values: {
                                ...form.values,
                                recaptcha: value,
                              },
                            })
                          }}
                        />
                      </Cushion>
                      {hasSubmitted && errors.length > 0 && (
                        <ul>
                          {errors.map(error => (
                            <Typography component="li" preset="error">
                              {error}
                            </Typography>
                          ))}
                        </ul>
                      )}
                    </Cushion>
                    <Typography component="div" align="center">
                      <Button
                        theme="blue"
                        type="submit"
                        onClick={e => {
                          e.preventDefault()
                          setHasSubmitted(true)
                          if (errors.length > 0) {
                            // has errors, do not go to the next page
                            return null
                          }

                          return formRef.current.submit()
                        }}
                      >
                        Send
                      </Button>
                    </Typography>
                  </form>
                </Cushion>
              </Rectangle>
            </Shadow>
          </Overlap>
        </Cushion>
      </MaxWidth>
    </Frame>
  )
}

Contact.propTypes = {
  location: PropTypes.shape({}).isRequired,
}
export default Contact

export const pageQuery = graphql`
  query {
    contactDetailsJson {
      email
      mobile
      landline
    }
  }
`
