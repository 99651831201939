import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getShadow } from '../Theme/selectors';
import withTheme from '../Theme/withTheme';
import renderByCloning from '../utils/renderByCloning';

const defaultProps = {
  children: null,
  component: null,
  shadow: 'default',
  uiTheme: {},
};

const Shadow = ({ children, component, shadow, uiTheme, ...otherProps }) =>
  renderByCloning(component, children, { ...otherProps });

Shadow.propTypes = {
  children: PropTypes.any,
  component: PropTypes.string,
  shadow: PropTypes.string,
  uiTheme: PropTypes.object,
};
Shadow.defaultProps = defaultProps;

const StyledShadow = styled(Shadow)`
  box-shadow: ${props => getShadow(props.uiTheme, props.shadow)};
`;
StyledShadow.defaultProps = defaultProps;

export default withTheme(StyledShadow);
