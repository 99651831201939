import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Cushion from "../Cushion"
import Rectangle from "../Rectangle"
import Typography from "../Typography"

const TextAreaEl = (props) => {
  return <Typography component="textarea" preset="body" color="darkGrey" {...props}  />
}

const StyledTextArea = styled(TextAreaEl)`
  display: block;
  width: 100%;
  box-sizing: border-box;
  ${props => (props.hidden ? "display: none;" : "")}
  ${props => (props.disabled ? "cursor: not-allowed;" : "")}

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #b2b2b2;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #b2b2b2;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: #b2b2b2;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: #b2b2b2;
  }
`

const TextArea = React.forwardRef((props, ref) => {
  const {
    disabled,
    hidden,
    input = {},
    meta: { touched = false, error = false },
    style: customStyle,
    ...otherProps
  } = props

  return (
    <React.Fragment>
      <Rectangle fill="white" border="solid 1px #B7B7B7" radius="rounded">
        <Cushion all="small">
          <StyledTextArea
            ref={ref}
            hidden={hidden}
            disabled={disabled}
            style={customStyle}
            {...input}
            {...otherProps}
          />
        </Cushion>
      </Rectangle>
      {touched && error && (
        <Cushion top="xxsmall">
          <Typography component="label" preset="description" color="#E26868">
            {error}
          </Typography>
        </Cushion>
      )}
    </React.Fragment>
  )
})

TextArea.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
}

TextArea.defaultProps = {
  meta: {},
  style: {},
  disabled: false,
  hidden: false,
}

export default TextArea

